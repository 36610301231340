import { ReactiveTraderIcon } from "./types"

export const RefreshIcon = ({
  width = 16,
  height = 16,
}: ReactiveTraderIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="currentColor">
      <path d="M14.55 22.42C14.22 22.42 13.91 22.2 13.82 21.86C13.71 21.46 13.95 21.05 14.36 20.94C18.42 19.87 21.25 16.19 21.25 11.99C21.25 6.89 17.1 2.74 12 2.74C7.67 2.74 4.83 5.27 3.5 6.8H6.44C6.85 6.8 7.19 7.14 7.19 7.55C7.19 7.96 6.86 8.31 6.44 8.31H2.01C1.96 8.31 1.87 8.3 1.8 8.28C1.71 8.25 1.63 8.21 1.56 8.16C1.47 8.1 1.4 8.02 1.35 7.93C1.3 7.84 1.26 7.73 1.25 7.62C1.25 7.59 1.25 7.57 1.25 7.54V3C1.25 2.59 1.59 2.25 2 2.25C2.41 2.25 2.75 2.59 2.75 3V5.39C4.38 3.64 7.45 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 16.88 19.46 21.16 14.74 22.4C14.68 22.41 14.61 22.42 14.55 22.42Z" />
      <path d="M11.29 22.73C11.27 22.73 11.25 22.72 11.24 22.72C10.16 22.65 9.1 22.41 8.1 22.02C7.81 21.91 7.61 21.62 7.62 21.31C7.62 21.22 7.64 21.13 7.67 21.05C7.82 20.67 8.27 20.48 8.64 20.62C9.51 20.96 10.42 21.16 11.34 21.23C11.73 21.25 12.04 21.59 12.04 21.99L12.03 22.03C12.01 22.42 11.68 22.73 11.29 22.73ZM5.78 20.58C5.61 20.58 5.45 20.52 5.31 20.42C4.47 19.74 3.73 18.95 3.13 18.07C3.04 17.94 2.99 17.8 2.99 17.65C2.99 17.4 3.11 17.17 3.32 17.03C3.65 16.8 4.13 16.89 4.36 17.21C4.36 17.22 4.36 17.22 4.36 17.22C4.37 17.23 4.38 17.25 4.39 17.26C4.91 18.01 5.54 18.68 6.25 19.24C6.42 19.38 6.53 19.59 6.53 19.82C6.53 19.99 6.48 20.16 6.37 20.3C6.22 20.48 6.01 20.58 5.78 20.58ZM2.44 15.7C2.11 15.7 1.82 15.49 1.73 15.18C1.41 14.15 1.25 13.08 1.25 12V11.99C1.26 11.58 1.59 11.25 2 11.25C2.41 11.25 2.75 11.59 2.75 12C2.75 12.94 2.89 13.86 3.16 14.73C3.18 14.81 3.19 14.88 3.19 14.96C3.19 15.28 2.98 15.57 2.66 15.67C2.59 15.69 2.52 15.7 2.44 15.7Z" />
    </g>
  </svg>
)
